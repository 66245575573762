// import mixpanel from "mixpanel-browser"
import { devLogging } from "./functions"

// export class MxPanelEvents {
// 	public RECT_APP_ENABLE_MXPANEL: string = String(process.env.RECT_APP_ENABLE_MXPANEL)
// 	constructor() {
// 		mixpanel.init(String(process.env.REACT_APP_MXPANEL_TOKEN), {
// 			debug: false,
// 			track_pageview: false,
// 			persistence: "localStorage",
// 		})
// 	}

// 	identifyUser(email: string): void {
// 		if (this.RECT_APP_ENABLE_MXPANEL === "true") {
// 			devLogging(`mxPanelHelper -> logIdentity -> email: ${email}`)
// 			mixpanel.identify(email)
// 		}
// 	}

// 	logCustomEvent(eventName: string, eventAtributes: any): void {
// 		if (this.RECT_APP_ENABLE_MXPANEL === "true") {
// 			devLogging(`mxPanelHelper -> logCustomEvent -> eventName: ${eventName}`, eventAtributes)
// 			mixpanel.track(eventName, eventAtributes)
// 		}
// 	}

// 	logPageViewEvent(eventAtributes: any): void {
// 		if (this.RECT_APP_ENABLE_MXPANEL === "true") {
// 			devLogging(`mxPanelHelper -> logPageViewEvent -> eventAttributes: ${eventAtributes}`)
// 			mixpanel.track_pageview(eventAtributes)
// 		}
// 	}
// }

export class MxPanelEvents {
	public RECT_APP_ENABLE_MXPANEL: string = String(process.env.RECT_APP_ENABLE_MXPANEL)
	identifyUser(email: string): void {
		devLogging(`mxPanelHelper -> logIdentity -> email: ${email}`)
	}
	logCustomEvent(eventName: string, eventAtributes: any): void {
		devLogging(`mxPanelHelper -> logCustomEvent -> eventName: ${eventName}`, eventAtributes)
	}
	logPageViewEvent(eventAtributes: any): void {
		devLogging(`mxPanelHelper -> logPageViewEvent -> eventAttributes: ${eventAtributes}`)
	}
}
