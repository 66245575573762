export enum mxPanelEventName {
	PAGE_VIEW = "Page View",
	BUTTON_CLICK = "Button Click",
	OTHER = "Other",
}

export interface IButtonClickEventAttributes {
	button_url?: string
	button_text?: string
	date_time?: string
	module_title?: string
	page_title: string
	page_url: string
	event_type?: string
	platformName?: string
}

export interface ILinkClickEventAttributes {
	link_label: string | undefined
	link_url: string
	module_title?: string
	page_title: string
	page_url: string
}

export interface IPageViewEventAttributes {
	page_title: string
	page_url: string
	page_referrer?: string
}
