import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { Provider } from "mobx-react"
import reportWebVitals from "./reportWebVitals"
import { RootStore } from "./utils/stores"
import Logger from "./utils/logger"

Logger.startSession()

const rootStore = new RootStore()

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider rootStore={rootStore}>
				<App />
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root"),
)

reportWebVitals()
