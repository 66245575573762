import React, { useState } from "react";
import "./button.css";

export function buttonClickHandler(
  setClicked: (clicked: boolean) => void,
  propsOnClicked: () => void
) {
  setClicked(true);

  if (propsOnClicked !== undefined) {
    propsOnClicked();
  }
}

export function PrimaryButton(
  props: any & {
    href: string | undefined;
    children?: JSX.Element | JSX.Element[] | string;
  }
) {
  const [clicked, setClicked] = useState(false);

  return (
    <div className="button-container">
      <a
        {...props}
        href={props.href}
        className={`primary-button ${clicked ? "" : ""}`}
        onClick={(_) => buttonClickHandler(setClicked, props.onClick)}
      >
        <p>{props.children}</p>
      </a>
    </div>
  );
}
