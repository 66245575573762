import { makeAutoObservable } from "mobx"
import { FormState, FieldState } from "formstate"
import { getTransactionDetails } from "./requests"
import { UserIdentities } from "@ikhokha/commons-ecomm/dist/types/Types"
import EventsTracker from "../helpers/eventsTracker"
import { MxPanelEvents } from "../helpers/mxPanel"

export type PayLinkData = {
	/**
	 * The amount in cents
	 */
	amount: number
	platformName: string
	status: string
	customerName?: string
	successUrl: string
	failUrl: string
	test: boolean
	cancelUrl?: string
	owner: string
	type: string
	tradingName?: string
	reference?: string
	customReference?: string
	createdAt: string
}

export type TransactionData = {
	amount: number
	successUrl: string
	failureUrl: string
	cancelUrl: string
	tryAgainUrl: string
	displayConfig?: {
		backToOrder: boolean
		backToMerchantStore: boolean
	}
	trackEvents?: boolean
	eventsUserIdentities?: UserIdentities
	maskedPan?: string
	cardExpiry?: string
}

export type Error = {
	message: string
	statusCode: number
}

// FIXME: Change this in the future
class PaylinkDataStore {
	rootStore: RootStore

	/**
	 * Fetches data from the micro services
	 */
	async fetchTransactionData(
		token: string,
	): Promise<{ success: true; data: TransactionData } | { success: false; error: Error }> {
		const fetchedData = await getTransactionDetails({ token })

		if (fetchedData.success) {
			const {
				amount,
				failureUrl,
				successUrl,
				cancelUrl,
				displayConfig,
				trackEvents,
				tryAgainUrl,
				eventsUserIdentities,
				maskedPan,
				cardExpiry,
			} = fetchedData.data

			const payData = {
				amount,
				failureUrl,
				successUrl,
				cancelUrl,
				displayConfig,
				trackEvents,
				tryAgainUrl,
				eventsUserIdentities,
				maskedPan,
				cardExpiry,
			}

			return {
				success: true,
				data: payData,
			}
		} else {
			const statusCode = fetchedData.statusCode

			let errorMessage

			switch (statusCode) {
				case 404:
					errorMessage = "Transaction Not Found"
					break
				case 500:
				default:
					errorMessage = "Please try again or contact support."
					break
			}

			return {
				success: false,
				error: {
					message: errorMessage,
					statusCode,
				},
			}
		}
	}

	// FIXME: There could be an issue where someone navigates from an order but also clicks a send-paylink
	// Naive solution should be fine for now
	setReferrerOnlyIfNotOrigin() {
		if (!document.referrer.startsWith(window.origin)) {
			window.localStorage.setItem("referrerSite", document.referrer)
		}
	}

	setPayRef(token: string) {
		window.localStorage.setItem("paymentRef", token)
	}

	get payRef(): string | undefined {
		const payRef = window.localStorage.getItem("paymentRef")
		return payRef || undefined
	}

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false })
		this.rootStore = rootStore
	}
}

type CardData = {
	/**
	 * The pan without spaces
	 * @example 11112222333444
	 */
	pan: string
	/**
	 * @example 02
	 */
	expiryMonth: string
	/**
	 * @example 28
	 */
	expiryYear: string
	/**
	 * The cards cvv
	 * @example 123
	 */
	cvv: string
	/**
	 * The card holders name
	 * @example John Smith
	 */
	cardholderName: string
}

type TypeToFormState<T extends Record<string, any>> = FormState<{
	[P in keyof T]: FieldState<T[P]>
}>

const isStringOfMinLength = (x: string, minLength: number) => x.length >= minLength
const isStringOfMaxLength = (x: string, maxLength: number) => x.length <= maxLength

class CardDataStore {
	rootStore: RootStore

	data: TypeToFormState<CardData>

	formatters: Record<keyof CardData, (str: string) => string> = {
		cardholderName: (str: string) => str.trim(),
		cvv: (str: string) => str.trim(),
		expiryMonth: (str: string) => str.trim(),
		expiryYear: (str: string) => str.trim(),
		pan: (str: string) => str.trim(),
	}

	constructor(rootStore: RootStore) {
		makeAutoObservable(this, { rootStore: false })
		this.rootStore = rootStore

		this.data = new FormState({
			// Mutate here possible to only have numbers for
			// pan
			// month
			// year
			// cvv
			pan: new FieldState("").validators((input) => {
				const min = 12
				const max = 19
				if (!isStringOfMinLength(input, min)) return `Must be at least ${min} characters`
				if (!isStringOfMaxLength(input, max)) return `Must be at most ${max} characters`
			}),
			// 	setFocus("expiryYear")
			expiryMonth: new FieldState("").validators((input) => {
				if (input.length !== 2) return `Must be at two characters`

				const monthAsDigit = parseInt(input)
				if (isNaN(monthAsDigit)) return "Not a valid number"

				if (monthAsDigit < 0 || monthAsDigit > 12) return "Not a valid month"
			}),
			// setFocus("cvv")
			expiryYear: new FieldState("").validators((input) => {
				if (input.length !== 2) return `Must be at two characters`
				const yearAsDigit = parseInt(input)
				if (isNaN(yearAsDigit)) return "Not a valid number"

				if (yearAsDigit < 0 || yearAsDigit > 99) return "Not a valid year"
			}),
			cvv: new FieldState("").validators((input) => {
				const min = 3
				const max = 4
				if (!isStringOfMinLength(input, min)) return `Must be at least ${min} characters`
				if (!isStringOfMaxLength(input, max)) return `Must be at most ${max} characters`
			}),
			cardholderName: new FieldState("").validators((input) => {
				const min = 3
				const max = 64
				if (!isStringOfMinLength(input, min)) return `Must be at least ${min} characters`
				if (!isStringOfMaxLength(input, max)) return `Must be at most ${max} characters`
			}),
		})
	}
}

export class RootStore {
	payLinkDataStore: PaylinkDataStore
	cardDataStore: CardDataStore
	eventsTracker: EventsTracker

	constructor() {
		this.payLinkDataStore = new PaylinkDataStore(this)
		this.cardDataStore = new CardDataStore(this)
		this.eventsTracker = new EventsTracker(new MxPanelEvents())
	}
}
