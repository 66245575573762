import Logo from "../../assets/images/ik-logo-black-v2.svg";
import "./navbar.css";

import React from "react"
export default function Navbar() {
  return (
    <nav>
      <header>
        <div className="topBar-container">
          <img src={Logo} alt="iKhokha logo black" />
        </div>
      </header>
    </nav>
  );
}
