import { useEffect, useState } from "react"
// import { useNavigate } from "react-router-dom"
import FailIcon from "../../assets/images/error.svg"
import { PrimaryButton } from "../../components/buttons/PrimaryButton"
import { Card } from "../../components/cards/Card"
import { Container } from "../../components/container"
import Footer from "../../components/footer/Footer"
import Layout from "../../components/layout/Layout"
import cards from "../../config/cards"
// import { PlatformName } from "../../utils"
import Logger from "../../utils/logger"
// import { PageRoutes } from "../../utils/routes"
import getFromLocalStorage, { StorageKeys, StorageTypes } from "../../utils/storage"
import { RootStore, TransactionData } from "../../utils/stores"
import React from "react"
import { IButtonClickEventAttributes, IPageViewEventAttributes, mxPanelEventName } from "../../utils/mxPanel"

type FailPageProps = {
	rootStore: RootStore
}

// type PaylinkDataState = {
// 	success: true
// 	data: PayLinkData
// }

// const isPaylinkValidDataState = (data: any): data is PaylinkDataState => {
// 	return (data as PaylinkDataState).success === true && (data as PaylinkDataState).data.platformName === PlatformName.WOO_COMMERCE
// }

const FailurePage: React.FunctionComponent<FailPageProps> = ({ rootStore: { payLinkDataStore, eventsTracker } }) => {
	// const navigate = useNavigate()
	const payRef = payLinkDataStore.payRef
	const currentUrl = window.location.href
	const errorReason = getFromLocalStorage(StorageTypes.IKPayment, StorageKeys.ErrorReason)
	const errorSubReason = getFromLocalStorage(StorageTypes.IKPayment, StorageKeys.ErrorSubReason)
	const tryAgain = getFromLocalStorage(StorageTypes.IKPayment, StorageKeys.TryAgain)
	const message = `Error with Payment Ref# ${payRef}. Reason = ${errorReason}`
	const [transactionData, setTransactionData] = useState<TransactionData | undefined>()

	Logger.rumError(message)
	Logger.error(message)

	const retryExemptions = [
		"Amount exceeds maximum allowed by iKhokha.",
		"Amount exceeds maximum allowed by iKhokha",
		"The merchant is disabled.",
		"The merchant category code is restricted. Please contact your merchant or iK Pay Online at payonline@ikhokha.com",
		"This link has already been paid for",
		"The link has expired",
	]

	const retrySubExemptions = [
		"We are unable to process your transaction. Please check the transaction details and try again.",
		"Please try again or contact support.",
	]

	useEffect(() => {
		;(async () => {
			if (!payRef) return
			const response = await payLinkDataStore.fetchTransactionData(payRef)
			const logPageEvent: IPageViewEventAttributes = {
				page_url: `${window.location.href}`,
				page_title: "Transaction Failure",
			}
			eventsTracker.logCustomEvent(mxPanelEventName.PAGE_VIEW, logPageEvent)
			if (response.success) {
				setTransactionData(response.data)
			}
		})()
	}, [eventsTracker, payLinkDataStore, payRef])

	const checkUserFriendlyError = (): string | null => {
		if (errorReason === "Card Expired") return errorReason
		if (errorReason === "Please try again or contact support.") return errorReason
		if (errorReason === "Amount exceeds maximum allowed by iKhokha") return "iKhokha prevented transaction"
		else {
			return null
		}
	}

	const canRetry = (): boolean => {
		console.log(errorReason)
		if (tryAgain === "true" || tryAgain === "false") {
			return tryAgain === "true" ? true : false
		}

		if (errorSubReason) {
			return retrySubExemptions.includes(errorSubReason)
		}

		if (!errorReason) return true
		return !retryExemptions.includes(errorReason)
	}

	const displayPrimaryError = (error: string): string => {
		if (errorReason === "Please try again or contact support.") return ""
		return errorReason !== null && errorSubReason ? errorReason : checkUserFriendlyError() || "Error processing transaction"
	}

	const onTryAgain = (payRef: string) => {
		const event: IButtonClickEventAttributes = {
			page_title: "Transaction Failure",
			page_url: `${window.location.href}`,
			button_text: "Try Again",
			date_time: new Date().toDateString(),
			module_title: "Transaction Failure",
		}
		eventsTracker.logCustomEvent(mxPanelEventName.BUTTON_CLICK, event)
		window.location.href = transactionData?.tryAgainUrl || `${currentUrl.substring(0, currentUrl.lastIndexOf("/"))}/${payRef}`
	}

	return (
		<div>
			<Layout>
				<Container
					src={FailIcon}
					alt="Transaction Failed Icon"
					title="Transaction Failed"
					//TODO: replace this with the CNPError header prop
					errorMessage={displayPrimaryError(errorReason || "")}
					message={
						errorReason !== null && errorSubReason
							? errorSubReason
							: "We were unable to process your payment. Please try again or contact our support team for further help."
					}
				>
					{payRef && canRetry() && (
						<PrimaryButton
							style={{ fontSize: "18px" }}
							onClick={() => {
								const events: IButtonClickEventAttributes = {
									page_title: "Transaction Failure",
									page_url: window.location.href,
									button_text: `${transactionData?.displayConfig?.backToMerchantStore ? "Back to merchant store" : "Try Again"}`,
									date_time: new Date().toDateString(),
									module_title: "Transaction Failure",
								}
								eventsTracker.logCustomEvent(mxPanelEventName.BUTTON_CLICK, events)
								transactionData?.displayConfig?.backToMerchantStore
									? (window.location.href = transactionData.failureUrl)
									: onTryAgain(payRef)
							}}
						>
							{`${transactionData?.displayConfig?.backToMerchantStore ? "Back to merchant store" : "Try Again"}`}
						</PrimaryButton>
					)}
				</Container>
				<Footer />
				<div className="support_header">
					<h2>Got questions or need our help?</h2>
				</div>
				<div className="card_container">
					{cards.map((card, index) => {
						return (
							<Card
								key={index}
								src={card.src}
								alt={card.alt}
								heading={card.heading}
								href={card.href}
								message={card.message}
								altMessage={card.altMessage}
							/>
						)
					})}
				</div>
			</Layout>
		</div>
	)
}

export default FailurePage
