import { setLocalStorageData, StorageKeys, StorageTypes } from "./storage"
import { PayLinkData } from "./stores"

export enum PlatformName {
	HUBSPOT_INTERNAL_SALES = "hubspot internal sales",
	IK_SHOP_DIRECT_HUBSPOT = "ik_shop_direct_hubspot",
	PROFILE_IKHOKHA_COM = "profile_ikhokha_com",
	MOBILE_IKHOKHA = "mobile-ikhokha",
	WOO_COMMERCE = "WooCommerce",
	WIX = "Wix",
	STATEMENT_SERVICE = "StatementService",
}

export class FeatureFlags {
	public static isIftAllowed(platformName: string): boolean {
		const allowedIftPlatforms = [
			PlatformName.HUBSPOT_INTERNAL_SALES,
			PlatformName.IK_SHOP_DIRECT_HUBSPOT,
			PlatformName.PROFILE_IKHOKHA_COM,
			PlatformName.MOBILE_IKHOKHA,
			PlatformName.WOO_COMMERCE,
			PlatformName.STATEMENT_SERVICE,
		]
		return allowedIftPlatforms.includes(platformName as PlatformName)
	}

	public static ShouldShowBackToOrder(platformName: string): boolean {
		const allowedBackToOrder = [PlatformName.WOO_COMMERCE, PlatformName.IK_SHOP_DIRECT_HUBSPOT, PlatformName.WIX]
		return allowedBackToOrder.includes(platformName as PlatformName)
	}

	public static isTradingNameAllowed(platformName: string): boolean {
		const allowedIftPlatforms = [PlatformName.PROFILE_IKHOKHA_COM, PlatformName.MOBILE_IKHOKHA]
		return allowedIftPlatforms.includes(platformName as PlatformName)
	}
}

/**
 * If the pay link data is in an error state, an error message will be returned
 */
export const generateErrorMessage = ({
	status,
	amount,
	platformName,
}: PayLinkData): undefined | { useFriendly: boolean; message: string; subMessage?: string } => {
	if (status) {
		if (status === "PAID") return { useFriendly: true, message: "This link has already been paid for" }
		if (status === "EXPIRED") return { useFriendly: true, message: "The link has expired" }
		if (status === "ATTEMPTS_EXCEEDED") {
			return generateExpiredErrorMessage(platformName)
		}
	} else if (typeof status === "undefined" || status === null) {
		return { useFriendly: false, message: "This link is invalid. Status of this link is unknown." }
	}

	if (amount >= 8000001) {
		return {
			useFriendly: true,
			message: " iKhokha prevented transaction",
			subMessage: "Please notify the merchant or contact iKhokha",
		}
	}

	return
}

export const generateExpiredErrorMessage = (platformName: string): { useFriendly: boolean; message: string } => {
	if (platformName !== PlatformName.WOO_COMMERCE) {
		setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorSubReason, "Please request a new pay link from the merchant.")
	} else {
		setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorSubReason, "Too many payment attempts.")
	}

	return { useFriendly: true, message: "iKhokha prevented transaction" }
}

export const initializeHtml = (html: string) => {
	document.open("text/html", "replace")
	document.write(html)
	document.close()
}

export const threeDSResCodes = {
	SUCCESS: "SUCCESS",
	FAILURE: "FAILURE",
	PENDING_ENROLLMENT: "PENDING_ENROLLMENT",
	PENDING_ACS: "PENDING_ACS",
}

export enum PreliminaryChecksErrors {
	DISABLED_MERCHANT = "The merchant is disabled.",
	RESTRICTED_MERCHANT = "The merchant category code is restricted.",
}
