import Navbar from "../navbar/NavBar";
import "../../assets/styles/global.css";

import React from "react"

export default function Layout({ children }: { children: any }) {
  return (
    <div className="layout">
      <Navbar />
      <div>{children}</div>
    </div>
  );
}
