import React from "react"
import EventsTracker from "../../../helpers/eventsTracker"
import { IButtonClickEventAttributes, mxPanelEventName } from "../../../utils/mxPanel"

const linkStyle: React.CSSProperties = {
	fontSize: "16px",
	fontWeight: "600",
	color: "#09828b",
	letterSpacing: "1px",
	cursor: "pointer",
	background: "none",
	border: "none",
	appearance: "none",
	margin: "30px auto",
}

type ReturnToOrderProps = {
	returnUrl: string
	eventName?: string
	trackEvents?: boolean | undefined
	eventType?: string | undefined
	eventInfo?: string | undefined
	eventTracker: EventsTracker
}

const ReturnToOrder: React.FC<ReturnToOrderProps> = ({ returnUrl, trackEvents, eventTracker }) => {
	const onOpenReturnUrl = (event: any) => {
		event.preventDefault()
		const events: IButtonClickEventAttributes = {
			page_title: "Make a Credit or Debit Card Payment",
			page_url: window.location.href,
			button_text: "Return To Order",
			button_url: returnUrl,
			date_time: new Date().toDateString(),
			module_title: "Return To Order",
		}
		eventTracker.logCustomEvent(mxPanelEventName.BUTTON_CLICK, events)

		window.open(returnUrl, "_self")
	}
	return (
		<button style={linkStyle} onClick={onOpenReturnUrl}>
			Return to order
		</button>
	)
}

export default ReturnToOrder
