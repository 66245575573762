import IkhokhaLoader from "../../assets/images/iK-Load-Animation.gif"
import "./index.css"
import React from "react"


type LoaderProps = {
	message?: string
}

const Loader: React.FunctionComponent<LoaderProps> = ({ message = "Loading..." }) => {
	return (
		<div>
			<div className="loaderAnimContainer expanded">
				<img className=".loaderImage customIcon" src={IkhokhaLoader} alt="iKhokha Loader" />
				<label>{message}</label>
			</div>
		</div>
	)
}

export default Loader
