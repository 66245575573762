import React from "react"
import errorModule from "./error.module.css"
import ErrorIcon from "../../assets/images/error.svg"
import cards from "../../config/cards"
import { PageRoutes } from "../../utils/routes"
import { PrimaryButton } from "../buttons/PrimaryButton"
import { Card } from "../cards/Card"
import Footer from "../footer/Footer"
import Layout from "../layout/Layout"
import Logger from "../../utils/logger"
import { useNavigate } from "react-router-dom"

interface IError {
	/**
	 * The error sub heading
	 */
	errorMessage?: string
	/**
	 * The main error heading
	 */
	titleMessage: string
	/**
	 * Give the paylink token for retry purposes
	 */
	tokenForRetry?: string
}

const errorTitleStyle: React.CSSProperties = {
	color: "#E54360",
}

const ErrorComponent: React.FunctionComponent<IError> = (props) => {
	const navigate = useNavigate()
	const token = props.tokenForRetry
	Logger.rumError(`An Error Occurred: Message = ${props.titleMessage} ${props.errorMessage}. Token = ${token}`)

	return (
		<>
			<Layout>
				<main className={errorModule.main_container}>
					<section className={errorModule.main_section}>
						<div className={errorModule.notFound_container}>
							<img src={ErrorIcon} alt="Error Page" />
							<h1>{props.titleMessage}</h1>
							{props.errorMessage && <h3 style={errorTitleStyle}>{props.errorMessage}</h3>}
							<p className={errorModule.message}>We were unable to process your payment. Please try again or contact our support team for further help.</p>
							{token && (
								<PrimaryButton
									onClick={() => {
										navigate(PageRoutes["HomePage"](token))
									}}
								>
									Go to Home
								</PrimaryButton>
							)}
						</div>
					</section>
				</main>
				<Footer />
				<div className="support_header">
					<h2>Got questions or need our help?</h2>
				</div>
				<div className="card_container">
					{cards.map((card, index) => {
						return (
							<Card
								key={index}
								src={card.src}
								alt={card.alt}
								heading={card.heading}
								href={card.href}
								message={card.message}
								altMessage={card.altMessage}
							/>
						)
					})}
				</div>
			</Layout>
		</>
	)
}
export default ErrorComponent
