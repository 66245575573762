import React, { useState, useEffect } from "react"
import { observer } from "mobx-react"
import { isPerformTransactionCNPResponse, performTransaction } from "../../utils/requests"
import { RootStore } from "../../utils/stores"
import Loader from "../loader"
import { threeDSResCodes } from "../../utils"
import { setLocalStorageData, StorageKeys, StorageTypes } from "../../utils/storage"
import "./challengeResponse.css"
import Logger from "../../utils/logger"
import { ResponseCode } from "@ikhokha/commons-ecomm/dist/types/Enum"

type cResPageProps = {
	rootStore: RootStore
	isSuccessRoute: boolean
}

const LoaderThatRedirects: React.FunctionComponent<{ url: string; message: string }> = ({ message, url }) => {
	useEffect(() => {
		setTimeout(() => {
			window.location.href = url
		}, 500)
	})
	return <Loader message={message} />
}

const ChallengeResponse: React.FunctionComponent<cResPageProps> = observer(
	({ rootStore: { payLinkDataStore }, isSuccessRoute }) => {
		const [errorMessage, setErrorMessage] = useState<{ useFriendly: boolean; message: string; canRetry?: boolean } | undefined>()
		const [isLoading, setLoading] = useState<boolean>(true)
		const [redirectOptions, setRedirect] = useState<{ hasError: boolean; url: string; message: string }>({
			hasError: true,
			url: "/failure",
			message: "Transaction unsuccessful, redirecting ...",
		})

		// const staticFailureURL = `/failure`

		// /**
		//  * Based on the paylink data object passed, this function will be
		//  * used to determine if we should allow retry for WooCommerce
		//  * @param payLinkDataLocal the data object containing paylink data
		//  */
		// function allowRetryForWooCommerce(payLinkDataLocal: PayLinkData) {
		// 	if (payLinkDataLocal.platformName === PlatformName.WOO_COMMERCE) {
		// 		setLocalStorageData(StorageTypes.IKPayment, StorageKeys.TryAgain, "true")
		// 	}
		// }

		// /**
		//  * Based on the given paylink data object, this function will set the appropriate failure
		//  * page for the WooCommerce flow in the event that the given payment fails.
		//  * @param payLinkDataLocal provide the paylink data object and it will be
		//  * used to determine if we should redirect to the default failure page
		//  * @returns true if paylink is linked to a WooCommerce order, else false
		//  */
		// function redirectWooCommercePaylinkToDefaultFailurePage(payLinkDataLocal: PayLinkData): boolean {
		// 	let isWooCommerce: boolean = false
		// 	if (payLinkDataLocal.platformName === PlatformName.WOO_COMMERCE) {
		// 		isWooCommerce = true
		// 		setRedirect((previous) => ({ ...previous, ...{ url: staticFailureURL } }))
		// 	}
		// 	return isWooCommerce
		// }

		useEffect(() => {
			;(async () => {
				setLoading(true)

				if (!payLinkDataStore.payRef) {
					const error = { message: "Could not find data for paylink", useFriendly: false }
					setErrorMessage(error)
					// logger
					Logger.error(error.message)
					setLoading(false)
					return
				}

				const receivedData = await payLinkDataStore.fetchTransactionData(payLinkDataStore.payRef)

				let failUrl = "/failure"
				let successUrl = "/success"

				if (receivedData.success) {
					const transactionData = receivedData.data

					// Set our return links
					if (transactionData.failureUrl) failUrl = transactionData.failureUrl
					if (transactionData.successUrl) successUrl = transactionData.successUrl

					// Check that we are on the success route
					if (!isSuccessRoute) {
						// if (!redirectWooCommercePaylinkToDefaultFailurePage(payLinkDataLocal)) {
						if (!transactionData.displayConfig?.backToOrder) {
							setRedirect((previous) => ({ ...previous, ...{ url: failUrl } }))
						}
					} else {
						//should pref

						const performTransactionResponse = await performTransaction(payLinkDataStore.payRef)

						if (isPerformTransactionCNPResponse(performTransactionResponse)) {
							if (performTransactionResponse.responseCode === ResponseCode.SUCCESS) {
								if (performTransactionResponse.responseStatus === threeDSResCodes.SUCCESS) {
									Logger.info(`3DS was successful for Payment Ref: ${payLinkDataStore.payRef}`)
									setRedirect({
										hasError: false,
										url: transactionData.displayConfig?.backToMerchantStore ? `https://${window.location.hostname}/success` : successUrl,
										message: "Transaction successful, redirecting ...",
									})
								} else {
									//set error message
									const message = "Invalid 3DS Response"
									Logger.error(`Invalid 3DS Response for Payment Ref: ${payLinkDataStore.payRef}`)
									setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorReason, message)
									// if (!redirectWooCommercePaylinkToDefaultFailurePage(payLinkDataLocal)) {
									if (!transactionData.displayConfig?.backToOrder) {
										setRedirect((previous) => ({ ...previous, ...{ url: failUrl } }))
									}
								}
							} else {
								Logger.error(`Error performing transaction for Payment Ref: ${payLinkDataStore.payRef}`)
								if (performTransactionResponse.errorResponse) {
									setLocalStorageData(
										StorageTypes.IKPayment,
										StorageKeys.ErrorReason,
										performTransactionResponse.errorResponse.displayedErrorHeader || "",
									)
									setLocalStorageData(
										StorageTypes.IKPayment,
										StorageKeys.ErrorSubReason,
										performTransactionResponse.errorResponse?.displayedErrorMessage ||
											"Bank prevented transaction. Please increase your online limit or contact your bank",
									)
									setLocalStorageData(
										StorageTypes.IKPayment,
										StorageKeys.TryAgain,
										performTransactionResponse.errorResponse?.displayTryAgain ? "true" : "false",
									)
								}
							}
						} else {
							setLocalStorageData(
								StorageTypes.IKPayment,
								StorageKeys.ErrorReason,
								performTransactionResponse.responseMessage || "",
							)

							if (!transactionData.displayConfig?.backToOrder) {
								setRedirect((previous) => ({ ...previous, ...{ url: failUrl } }))
							}
						}
					}
				} else {
					// TODO: Add message
					const message = "General error getting data"
					Logger.error(`General error getting data for Payment Ref: ${payLinkDataStore.payRef}`)
					setErrorMessage({ useFriendly: true, message: message, canRetry: true })
					setRedirect((previous) => ({ ...previous, ...{ url: failUrl } }))
				}

				setLoading(false)
			})()
			// FIXME: Fix this in future
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		return isLoading ? (
			// Show our loader
			<Loader message={"Processing your payment"} />
		) : !errorMessage ? (
			// When loading is done, and there isn't an error, do the redirect
			<LoaderThatRedirects message={redirectOptions.message} url={redirectOptions.url} />
		) : (
			// If there is an error, display the message
			<LoaderThatRedirects message={"Redirecting..."} url={`https://${window.location.hostname}/failure`} />
		)
	},
)

export default ChallengeResponse
