type eventTracker = {
	// eslint-disable-next-line
	identifyUser: (email: string) => void
	// eslint-disable-next-line
	logCustomEvent: (eventName: string, eventAtributes?: any) => void
	// eslint-disable-next-line
	logPageViewEvent: (eventAtributes: any) => void
}

export default class EventsTracker {
	/*eslint no-unused-vars: "error"*/
	public eventTracker: eventTracker
	public RECT_APP_ENABLE_MXPANEL: string = String(process.env.RECT_APP_ENABLE_MXPANEL)

	constructor(eventTracker: eventTracker) {
		this.eventTracker = eventTracker
	}

	identifyUser(email: string): void {
		if (this.RECT_APP_ENABLE_MXPANEL === "true") {
			try {
				this.eventTracker.identifyUser(email)
			} catch (e) {
				console.error("eventTrackerHelper -> userIdentityEvent failed", e)
			}
		}
	}

	logCustomEvent(eventName: string, eventAtributes?: any): void {
		if (this.RECT_APP_ENABLE_MXPANEL === "true") {
			try {
				this.eventTracker.logCustomEvent(eventName, eventAtributes)
			} catch (e) {
				console.error("eventTrackerHelper -> logCustomEvent failed", e)
			}
		}
	}

	logPageViewEvent(eventAtributes: any): void {
		if (this.RECT_APP_ENABLE_MXPANEL === "true") {
			try {
				this.eventTracker.logPageViewEvent(eventAtributes)
			} catch (e) {
				console.error("eventTrackerHelper -> pageViewEvent failed", e)
			}
		}
	}
}
