const isNotProduction = () => {
	return !window.location.hostname.endsWith("ikhokha.com")
}
/**
 * For development and testing logs only
 */
export function devLogging(message: string, data?: unknown, bypass?: boolean, level?: "error" | "warn"): void {
	if (bypass || isNotProduction()) {
		const args = [message, data]
		if (level && level === "error") {
			args.push("color: red;")
		} else if (level && level === "warn") {
			args.push("color: yellow;")
		}
		console.log(...args.filter((i) => !!i))
	}
}
