import "./card.css"

import React from "react"
//TODO: REFACTOR THE TYPES/ INTERFACE
export default interface ICard {
	src: string
	alt: string
	key?: any
	href?: string
	message?: string
	altMessage?: string
	heading: string
}

export const Card: React.FunctionComponent<ICard> = ({ ...props }) => {
	return (
		<div>
			<div className="card" key={props.key}>
				<div className="icons">
					<img src={props.src} alt={props.alt} />
				</div>
				<div className="content-text">
					<p>{props.heading}</p>
					{props.href && (
						<p>
							<a href={props.href}>{props.message}</a>
						</p>
					)}
					{props.altMessage && <p>{props.altMessage}</p>}
				</div>
			</div>
		</div>
	)
}
