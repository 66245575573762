import { FC, useEffect } from "react"
import styles from "./index.module.css"
import Layout from "../../components/layout/Layout"
import { useNavigate } from "react-router-dom"
import toolTip from "../../assets/images/tool-tip.svg"
import pciThreeDS from "../../assets/images/pci_logo.svg"
import tDS from "../../assets/images/3ds_logo copy.svg"

interface ThreeDSProps {}

const ThreeDS: FC<ThreeDSProps> = () => {
	const navigate = useNavigate()
	const ThreeDS_HTML = sessionStorage.getItem("ThreeDS")
	const token = localStorage.getItem("paymentRef")

	useEffect(() => {
		if (ThreeDS_HTML == null) {
			navigate(`/${token}`)
		}
	})

	return (
		<Layout>
			<div className={styles.Main}>
				<div className={styles.Banner}>
					<img className={styles.Tooltip} src={toolTip} alt="tool-tip" />
					<h6 className={styles.Text}>
						Your bank needs your approval to proceed - please follow the instructions below to complete this payment transaction.
						This process may take up to a minute.
					</h6>
				</div>
				<div className={styles.Content}>
					{<iframe scrolling={"no"} className={styles.Frame} srcDoc={ThreeDS_HTML!} title={"ThreeDS"} />}
				</div>
				<div>
					<img className={styles.PCI_ThreeDS} src={tDS} alt="PCI_3DS" />
					<img className={styles.PCI_ThreeDS} src={pciThreeDS} alt="PCI_3DS" />
				</div>
			</div>
		</Layout>
	)
}

export default ThreeDS
