enum APP_ENV_VARS {
	"REACT_APP_IK_PAYMENT_CNP_SERVICE" = "REACT_APP_IK_PAYMENT_CNP_SERVICE",
	"REACT_APP_DATADOG_ENABLED" = "REACT_APP_DATADOG_ENABLED",
	"REACT_APP_STAGE" = "REACT_APP_STAGE",
	"REACT_APP_SERVICE" = "REACT_APP_SERVICE",
	"REACT_APP_MONITORING_ENDPOINT" = "REACT_APP_MONITORING_ENDPOINT",
	"REACT_APP_DATADOG_APP_ID" = "REACT_APP_DATADOG_APP_ID",
	"REACT_APP_DATADOG_CLIENT_TOKEN" = "REACT_APP_DATADOG_CLIENT_TOKEN",
	"REACT_APP_TIMEOUT" = "REACT_APP_TIMEOUT",
	"REACT_APP_HOTJAR_ID" = "REACT_APP_HOTJAR_ID",
	"REACT_APP_HOTJAR_SV" = "REACT_APP_HOTJAR_SV",
}
function getEnvVal(key: APP_ENV_VARS): string {
	const val = process.env[key]
	if (val) {
		return val
	}

	throw new Error(`Environment variable '${key}' is required`)
}

const dataDogEnabled = getEnvVal(APP_ENV_VARS.REACT_APP_DATADOG_ENABLED)

let dataDogConfig: { datadogAppId: string; datadogClientToken: string } | undefined

if (dataDogEnabled === "true") {
	dataDogConfig = {
		datadogAppId: getEnvVal(APP_ENV_VARS.REACT_APP_DATADOG_APP_ID),
		datadogClientToken: getEnvVal(APP_ENV_VARS.REACT_APP_DATADOG_CLIENT_TOKEN),
	}
}

export const appConfig = {
	paymentCNPServiceUrl: getEnvVal(APP_ENV_VARS.REACT_APP_IK_PAYMENT_CNP_SERVICE),
	stage: getEnvVal(APP_ENV_VARS.REACT_APP_STAGE),
	service: getEnvVal(APP_ENV_VARS.REACT_APP_SERVICE),
	monitoringEndpoint: getEnvVal(APP_ENV_VARS.REACT_APP_MONITORING_ENDPOINT),
	enableDatadog: getEnvVal(APP_ENV_VARS.REACT_APP_DATADOG_ENABLED),
	...dataDogConfig,
	timeout: Number(getEnvVal(APP_ENV_VARS.REACT_APP_TIMEOUT)),
	hotjarId: Number(getEnvVal(APP_ENV_VARS.REACT_APP_HOTJAR_ID)),
	hotjarSV: Number(getEnvVal(APP_ENV_VARS.REACT_APP_HOTJAR_SV)),
}
