import Layout from "../../components/layout/Layout"
import { Container } from "../../components/container"
import SuccessIcon from "../../assets/images/success.svg"
import { PrimaryButton } from "../../components/buttons/PrimaryButton"
import { RootStore, TransactionData } from "../../utils/stores"
import { useEffect, useState } from "react"
// import { PlatformName } from "../../utils"

import React from "react"
import { IPageViewEventAttributes, mxPanelEventName } from "../../utils/mxPanel"
export type SuccessPageProps = {
	rootStore: RootStore
}

const SuccessPage: React.FunctionComponent<SuccessPageProps> = ({ rootStore: { payLinkDataStore, eventsTracker } }) => {
	// const [merchantUrl, setMerchantUrl] = useState("")
	const [transactionData, setTransactionData] = useState<TransactionData | undefined>()

	useEffect(() => {
		;(async () => {
			const token = payLinkDataStore.payRef
			if (token) {
				const transactionData = await payLinkDataStore.fetchTransactionData(token)
				if (transactionData.success) {
					// if (paylinkData.data.platformName === PlatformName.WOO_COMMERCE) {
					setTransactionData(transactionData.data)
					// }
				}
				const event: IPageViewEventAttributes = {
					page_title: "Transaction Success",
					page_url: window.location.href,
				}
				eventsTracker.logCustomEvent(mxPanelEventName.PAGE_VIEW, event)
			}
		})()
	}, [eventsTracker, payLinkDataStore])

	// merchantUrl.length === 0 this means we are dealing with a send paylink
	const backToMerchantStore = transactionData?.displayConfig?.backToMerchantStore
	const messageInput = !backToMerchantStore ? "We will notify the merchant of your payment.\n You can close this tab now." : ""
	return (
		<div>
			<Layout>
				<Container
					src={SuccessIcon}
					alt="Transaction Successful Icon"
					title="Transaction"
					subtitle="successful"
					message={messageInput}
				>
					{backToMerchantStore && (
						<PrimaryButton
							style={{ fontSize: "18px", margin: "20px 0px 0px 0px", width: "340px" }}
							onClick={() => backToMerchantStore && (window.location.href = transactionData.successUrl)}
						>
							Back to merchant store
						</PrimaryButton>
					)}
				</Container>
			</Layout>
		</div>
	)
}

export default SuccessPage
