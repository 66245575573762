import { Buffer } from "buffer" // Warning: Do not change this. Due to tight types coupling between react and node, Buffer will exist within the context of Node, but will not exist at runtime. This package is required
import { observer } from "mobx-react"
import React, { useEffect, useRef, useState } from "react"
import cardPaymentModule from "./index.module.css"
import { useNavigate, useParams } from "react-router-dom"
import ReturnToOrder from "../../components/buttons/returnToOrder"
import Layout from "../../components/layout/Layout"
import Loader from "../../components/loader"
import {
	// FeatureFlags,
	// generateErrorMessage,
	generateExpiredErrorMessage,
	// PlatformName,
	threeDSResCodes,
} from "../../utils"
import {
	cancelTransaction,
	isThreeDsEnrollResponse,
	isThreeDsPreEnrollResponse,
	threeDsEnroll,
	threeDsPreEnroll,
} from "../../utils/requests"
import { setLocalStorageData, StorageKeys, StorageTypes } from "../../utils/storage"
import { RootStore, TransactionData } from "../../utils/stores"
import MasterCardLogo from "../../assets/images/master-card-logo-v2.svg"
import paymentbgBtm from "../../assets/images/paymentbg-btm.svg"
import paymentbgTop from "../../assets/images/paymentbg-top.svg"
import tickMark from "../../assets/images/tickMark.svg"
import VisaLogo from "../../assets/images/visa-logo-v2.svg"
import Logger from "../../utils/logger"
import { PageRoutes } from "../../utils/routes"
// import Footer from "../../components/footer/Footer"
import { ResponseCode } from "@ikhokha/commons-ecomm/dist/types/Enum"
import { formatCurrency } from "../../utils/utils"
import { IButtonClickEventAttributes, IPageViewEventAttributes, mxPanelEventName } from "../../utils/mxPanel"

type CardPaymentPageProps = {
	rootStore: RootStore
}

const LoaderThatRedirects: React.FunctionComponent<{ success: boolean; message: string }> = ({
	success,
	message = "Redirecting...",
}) => {
	useEffect(() => {
		setTimeout(() => {
			const redirectPage = success ? PageRoutes["Success"] : PageRoutes["Failure"]
			window.location.replace(`https://${window.location.hostname}${redirectPage()}`)
			// navigate(redirectPage())
		}, 500)
	})
	return <Loader message={message} />
}

const CardPaymentPage: React.FunctionComponent<CardPaymentPageProps> = observer(
	({ rootStore: { payLinkDataStore, cardDataStore, eventsTracker } }) => {
		const navigate = useNavigate()
		const { token } = useParams()
		const pageTitle = "Make a Credit or Debit Card Payment"
		if (!token) throw new Error("Path param :token is not defined")

		const [error, setErrorMessageState] = useState<{ useFriendly: boolean; message: string; canRetry?: boolean } | undefined>()
		const [isLoading, setLoading] = useState<boolean>(true)
		const [canSubmitForm, setCanSubmitForm] = useState<boolean>(false)

		const [transactionData, setTransactionData] = useState<TransactionData | undefined>()
		const yearField = useRef<HTMLInputElement | null>(null)

		const setErrorMessage = (input: { useFriendly: boolean; message: string; canRetry?: boolean; subMessage?: string }) => {
			setErrorMessageState(input)
			setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorReason, input.useFriendly ? input.message : "")
			if (input.subMessage) {
				setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorSubReason, input.useFriendly ? input.subMessage : "")
			}
		}

		useEffect(() => {
			;(async () => {
				sessionStorage.removeItem("ThreeDS")
				setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorSubReason, "")
				setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorReason, "")
				setLocalStorageData(StorageTypes.IKPayment, StorageKeys.TryAgain, "")

				setLoading(true)

				const receivedData = await payLinkDataStore.fetchTransactionData(token)

				if (receivedData.success) {
					const clientId = receivedData.data.eventsUserIdentities?.clientId
					if (clientId) {
						eventsTracker.identifyUser(clientId)
					}
					const events: IPageViewEventAttributes = {
						page_title: pageTitle,
						page_url: `${window.location.href}`,
						page_referrer: window?.localStorage?.getItem("referrerSite") ?? "",
					}
					eventsTracker.logCustomEvent(mxPanelEventName.PAGE_VIEW, events)

					payLinkDataStore.setPayRef(token)
					setTransactionData(receivedData.data)
					if (receivedData.data.maskedPan && receivedData.data.cardExpiry) {
						cardDataStore.data.$.pan.onChange(receivedData.data.maskedPan)
						cardDataStore.data.$.expiryYear.onChange(receivedData.data.cardExpiry.slice(0, 2))
						cardDataStore.data.$.expiryMonth.onChange(receivedData.data.cardExpiry.slice(2, 4))
					}
					// Track page view
				} else {
					Logger.error(
						`Error occurred while fetching transaction data: Pay Ref = ${token}. Error = ${JSON.stringify(receivedData.error)}`,
					)
					// FIXME: Remove status code
					setErrorMessage({
						useFriendly: true,
						message: receivedData.error.message,
					})
					setLoading(false)
					return
				}
				setLoading(false)
			})()
			// FIXME: Fix this in future
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
			e.preventDefault()
			sessionStorage.removeItem("ThreeDS")

			// Track button click
			const events: IButtonClickEventAttributes = {
				page_title: "Make a Credit or Debit Card Payment",
				page_url: `${window.location.href}`,
				button_text: "Pay",
				button_url: `${window.location.href}`,
				date_time: new Date().toDateString(),
			}
			eventsTracker.logCustomEvent(mxPanelEventName.BUTTON_CLICK, events)

			// Calling validate will trigger validation on each input
			const formValid = await cardDataStore.data.validate()

			if (formValid.hasError) {
				// Return early and allow the display to show validation errors
				return
			}

			setLoading(true)
			const cvv = cardDataStore.data.$.cvv.value
			// reset the CVV value so it doesn't get prefilled
			cardDataStore.data.$.cvv.reset()

			const preEnrollResponse = await threeDsPreEnroll({
				cardholderName: cardDataStore.data.$.cardholderName.value,
				cvv,
				expiryDate: cardDataStore.data.$.expiryYear.value + cardDataStore.data.$.expiryMonth.value,
				pan: cardDataStore.data.$.pan.value,
				token: token,
				purchaseAmount: transactionData!.amount.toString(),
			})

			// TODO: Refactor this in the future once fully understood
			if (preEnrollResponse.responseCode === ResponseCode.CNP_00 && isThreeDsPreEnrollResponse(preEnrollResponse)) {
				const { returnContent, status } = preEnrollResponse

				switch (status) {
					case threeDSResCodes.PENDING_ENROLLMENT:
						Logger.info(JSON.stringify({ returnContent, status }))
						Logger.info(`opening tdsMethodContent for pre enroll: Pay Ref = ${token}`)

						// initializeHtml(returnContent)
						Logger.info(`calling enroll now: Pay Ref = ${token}`)

						const enrollResponse = await threeDsEnroll({
							token: token,
						})
						if (enrollResponse.responseCode === ResponseCode.CNP_00 && isThreeDsEnrollResponse(enrollResponse)) {
							const { redirectToACSForm, status } = enrollResponse

							switch (status) {
								// Confirm if its suppose to be allowed to go in a loop
								case threeDSResCodes.PENDING_ENROLLMENT:
									setErrorMessage({ message: "Error during 3DS enrollment. Invalid status", useFriendly: true })
									Logger.error(`1. Error during 3DS enrollment: Pay Ref = ${token}. Data = ${JSON.stringify(enrollResponse)}`)
									break
								case threeDSResCodes.PENDING_ACS:
									Logger.info(JSON.stringify({ redirectToACSForm, status }))
									Logger.info(`opening redirect to acs form for enroll step: Pay Ref = ${token}`)

									// initializeHtml(redirectToACSForm)
									sessionStorage.setItem("ThreeDS", redirectToACSForm)
									navigate("3ds")

									Logger.info(`return after redirect to acs form for enroll step: Pay Ref = ${token}`)

									break
								case threeDSResCodes.SUCCESS:
									Logger.info(`3DS enrollment success: Pay Ref = ${token}.`)
									window.location.replace(
										`https://${window.location.hostname}/cres-redirect?paymentlinktoken=` +
											token +
											`&successUrl=${Buffer.from(transactionData!.successUrl).toString("base64")}&failUrl=${Buffer.from(
												transactionData!.failureUrl,
											).toString("base64")}`,
									)
									return

								case threeDSResCodes.FAILURE:
									Logger.error(`2. 3DS enrollment failure: Pay Ref = ${token}`)
									window.location.replace(
										`https://${window.location.hostname}/cres-failure?failUrl=` +
											Buffer.from(transactionData!.failureUrl).toString("base64") +
											`&paymentlinktoken=${token}`,
									)
									return
								default:
									Logger.error(`3. Error during 3DS enrollment: Pay Ref = ${token}. Data = ${JSON.stringify(enrollResponse)}`)
									// FIXME: Use generic message
									setErrorMessage({ message: "Error during 3DS enrollment. Invalid response", useFriendly: true })
									break
							}
						} else {
							Logger.error(`4.Error during 3DS enrollment: Pay Ref = ${token}. Response = ${JSON.stringify(enrollResponse)}`)
							// FIXME: Use generic message
							setErrorMessage({
								message: enrollResponse.responseMessage || "Error during 3DS enrollment. Invalid response",
								useFriendly: true,
							})
						}
						break
					case threeDSResCodes.PENDING_ACS: {
						Logger.info(`opening redirect to acs form in pre enroll: Pay Ref = ${token}`)

						Logger.info(JSON.stringify({ returnContent, status }))

						// FIXME: Not sure if this is possible. Don't think so though our endpoint types do allow for it
						// initializeHtml(returnContent)
						sessionStorage.setItem("ThreeDS", returnContent)
						navigate("3ds")

						Logger.info(`return redirect to acs form in pre enroll: Pay Ref = ${token}`)
						break
					}
					case threeDSResCodes.SUCCESS:
						Logger.info(`3DS pre enrollment success: Pay Ref = ${token}`)
						window.location.replace(
							`https://${window.location.hostname}/cres-redirect?paymentlinktoken=` +
								token +
								`&successUrl=${Buffer.from(transactionData!.successUrl).toString("base64")}&failUrl=${Buffer.from(
									transactionData!.failureUrl,
								).toString("base64")}`,
						)
						return
					case threeDSResCodes.FAILURE:
						Logger.error(`5. 3DS pre enrollment failure: Pay Ref = ${token}`)
						window.location.replace(
							`https://${window.location.hostname}/cres-failure?failUrl=` +
								Buffer.from(transactionData!.failureUrl).toString("base64") +
								`&paymentlinktoken=${token}`,
						)
						return

					default:
						Logger.error(`6. Error during 3DS pre enrollment: Pay Ref = ${token}`)
						// FIXME: Use generic message
						setErrorMessage({ message: "Error during 3DS pre enrollment. Invalid status", useFriendly: true })
						break
				}
			} else {
				console.log("error during 3ds pre " + JSON.stringify(preEnrollResponse))
				// FIXME: Use generic message
				if (
					preEnrollResponse.responseCode === ResponseCode.CNP_15 &&
					preEnrollResponse.responseMessage?.toLocaleLowerCase().includes("expired")
				) {
					Logger.error(`7. Card Expired. Pay Ref = ${token}. Message = ${preEnrollResponse.responseMessage}`)
					setErrorMessage({ message: "Card Expired", useFriendly: true, canRetry: true })
					setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorSubReason, "Use another payment method or card")
					setLocalStorageData(StorageTypes.IKPayment, StorageKeys.TryAgain, "true")
				} else if (
					preEnrollResponse.responseCode === ResponseCode.CNP_13 &&
					preEnrollResponse.responseMessage?.toLocaleLowerCase().includes("max payment attempts")
				) {
					const attemptsExpiredErrorMessage = generateExpiredErrorMessage("")
					setErrorMessage(attemptsExpiredErrorMessage)
				} else if (
					preEnrollResponse.responseCode === ResponseCode.CNP_01 &&
					preEnrollResponse.responseMessage === "The merchant is disabled."
				) {
					setErrorMessage({
						message: "iKhokha prevented transaction: Prohibited profile status.",
						subMessage: "Please notify the merchant or contact iKhokha",
						useFriendly: true,
					})
				} else if (
					preEnrollResponse.responseCode === ResponseCode.CNP_02 &&
					preEnrollResponse.responseMessage ===
						"The merchant category code is restricted. Please contact your merchant or iK Pay Online at payonline@ikhokha.com"
				) {
					setErrorMessage({
						message: "iKhokha prevented transaction: Merchant Category Code.",
						subMessage: "Please notify the merchant or contact iKhokha",
						useFriendly: true,
					})
				} else {
					setErrorMessage({
						message: preEnrollResponse.responseMessage || "Error during 3DS pre enrollment. Invalid response",
						useFriendly: true,
					})
					Logger.error(`8. Error during 3DS pre enrollment: Pay Ref = ${token}`)
				}
			}

			setLoading(false)
		}

		const checkCanSubmitForm = () => {
			setTimeout(() => {
				const fields = Object.keys(cardDataStore.data.$) as (keyof typeof cardDataStore.data.$)[]

				let formIsValid = true

				const inputsTouched = []

				for (const field of fields) {
					let fieldMessage = ""

					const fieldState = cardDataStore.data.$[field]

					if (fieldState.value.length <= 1) {
						// Set false and break early because we have a falsy value
						fieldMessage = "Short value"
					} else if (fieldState.hasError) {
						fieldMessage = "Has error"
					}

					if (fieldState.dirty) {
						inputsTouched.push(field)
					}

					if (fieldMessage) {
						formIsValid = false
					}
				}

				// Ensure each input has been updated
				// This is to safeguard against a race condition where validation may have not run yet because an input has not been edited yet
				const allTouched = inputsTouched.length === fields.length

				setCanSubmitForm(allTouched && formIsValid)
			}, 500)
		}

		if (error) {
			console.error(error.message)
		}

		return isLoading ? (
			<Loader />
		) : error ? (
			<LoaderThatRedirects success={false} message={""} />
		) : transactionData ? (
			<Layout>
				<div className={cardPaymentModule.ikpaymentContainer}>
					<img src={paymentbgTop} className={cardPaymentModule.paymentBgTop} alt="payment-bg-top" />
					<img src={paymentbgBtm} className={cardPaymentModule.paymentBgBtm} alt="payment-bg-btm" />

					<div className={cardPaymentModule.ikpaymentContainer__content}>
						<div className={cardPaymentModule.ikpaymentContainer__header}>
							<div className={cardPaymentModule.bankLogoPositioner}>
								<img src={VisaLogo} alt="visa logo" />
								<img src={MasterCardLogo} alt="mc logo" />
							</div>
							<h1>Make a Credit or Debit Card Payment</h1>
							<h4>Safe payments with iK Pay Online</h4>
						</div>

						<div className={cardPaymentModule.ikpaymentContainer__paymentForm}>
							<div className={cardPaymentModule.cardDetails}>
								<form onSubmit={handleSubmit} onChange={checkCanSubmitForm}>
									<div className={`${cardPaymentModule.field} ${cardPaymentModule.nameOnCard}`}>
										<input
											type="text"
											id="name-on-card"
											style={{
												border: cardDataStore.data.$.cardholderName.hasError ? "2px solid #E54360" : "",
											}}
											required
											minLength={3}
											maxLength={64}
											onChange={(e) => {
												const input = e.target.value
												// TODO: FIXME: Confirm sanitization
												const lettersAndSpacesOnly = input.replace(/[^A-Z ]/gi, "")
												cardDataStore.data.$.cardholderName.onChange(lettersAndSpacesOnly)
											}}
											value={cardDataStore.data.$.cardholderName.value}
										/>
										<label htmlFor="name-on-card">Name on Card</label>
										{cardDataStore.data.$.cardholderName.value.length > 1 && !cardDataStore.data.$.cardholderName.hasError && (
											<img
												// className="field-tick name-on-card__tick"
												className={cardPaymentModule.fieldTick}
												style={{
													display: "block",
												}}
												src={tickMark}
												alt="tick"
											/>
										)}
									</div>

									<div className={`${cardPaymentModule.field} ${cardPaymentModule.cardNumber}`}>
										<input
											type="tel"
											id="card-number"
											required
											style={{
												border: cardDataStore.data.$.pan.hasError ? "2px solid #E54360" : "",
											}}
											onChange={(e) => {
												const input = e.target.value
												const numbersOnly = input.replace(/[^0-9]/gi, "")
												cardDataStore.data.$.pan.onChange(numbersOnly)
											}}
											value={cardDataStore.data.$.pan.value.match(/.{1,4}/g)?.join(" ") || ""}
											maxLength={23}
											minLength={14}
										/>
										<label htmlFor="card-number">Card Number</label>
										<span className="card-type"></span>
										{cardDataStore.data.$.pan.value.length > 1 && !cardDataStore.data.$.pan.hasError && (
											<img
												// className="field-tick card-number__tick"
												className={cardPaymentModule.fieldTick}
												style={{
													display: "block",
												}}
												src={tickMark}
												alt="tick"
											/>
										)}
									</div>

									<div className={`${cardPaymentModule.fieldGroup} ${cardPaymentModule.cardExtra}`}>
										<div className={cardPaymentModule.leftBlock}>
											<div className={`${cardPaymentModule.expiryBlock} ${cardPaymentModule.month}`}>
												<input
													id="exp-month"
													placeholder="MM"
													type="tel"
													style={{
														border: cardDataStore.data.$.expiryMonth.hasError ? "2px solid #E54360" : "",
													}}
													onChange={(e) => {
														const input = e.target.value
														const numbersOnly = input.replace(/[^0-9]/gi, "")
														cardDataStore.data.$.expiryMonth.onChange(numbersOnly)
														if (Number(numbersOnly) <= 12 && numbersOnly.length >= 2) {
															if (yearField.current !== null) {
																yearField.current.focus()
															}
														}
													}}
													value={cardDataStore.data.$.expiryMonth.value}
													maxLength={2}
													minLength={2}
												/>
												<label>Exp. Month</label>
												{cardDataStore.data.$.expiryMonth.value.length > 1 && !cardDataStore.data.$.expiryMonth.hasError && (
													<img
														// className="field-tick exp-month__tick"
														className={cardPaymentModule.fieldTick}
														style={{
															display: "block",
														}}
														src={tickMark}
														alt="tick"
													/>
												)}
											</div>

											<span> / </span>

											<div className={`${cardPaymentModule.expiryBlock} ${cardPaymentModule.year}`}>
												<input
													id="exp-year"
													ref={yearField}
													placeholder="YY"
													type="tel"
													maxLength={2}
													minLength={2}
													style={{
														border: cardDataStore.data.$.expiryYear.hasError ? "2px solid #E54360" : "",
													}}
													onChange={(e) => {
														const input = e.target.value
														const numbersOnly = input.replace(/[^0-9]/gi, "")
														cardDataStore.data.$.expiryYear.onChange(numbersOnly)
													}}
													value={cardDataStore.data.$.expiryYear.value}
													required
												/>
												<label>Exp. Year</label>
												{cardDataStore.data.$.expiryYear.value.length > 1 && (
													<img
														// className="field-tick exp-year__tick"
														className={cardPaymentModule.fieldTick}
														style={{
															display: cardDataStore.data.$.expiryYear.hasError ? "none" : "block",
														}}
														src={tickMark}
														alt="tick"
													/>
												)}
											</div>
										</div>

										<div className={cardPaymentModule.rightBlock}>
											<input
												type="tel"
												id={cardPaymentModule.cvv__code}
												style={{
													border: cardDataStore.data.$.cvv.hasError ? "2px solid #E54360" : "",
												}}
												required
												onChange={(e) => {
													const input = e.currentTarget.value
													const numbersOnly = input.replace(/[^0-9]/gi, "")
													if (numbersOnly.length <= 4) {
														cardDataStore.data.$.cvv.onChange(numbersOnly)
													}
												}}
												value={cardDataStore.data.$.cvv.value}
												minLength={3}
												maxLength={4}
											/>
											<label htmlFor={cardPaymentModule.cvv__code}>
												CVV <span>?</span>
											</label>
											{cardDataStore.data.$.cvv.value.length > 1 && (
												<img
													// className="field-tick cvv-code__tick"
													className={cardPaymentModule.fieldTick}
													style={{
														display: cardDataStore.data.$.cvv.hasError ? "none" : "block",
													}}
													src={tickMark}
													alt="tick"
												/>
											)}
										</div>
									</div>

									<div className={cardPaymentModule.ikpaymentContainer__paymentFormButtonsPositioner}>
										<button className={cardPaymentModule.primary} disabled={!canSubmitForm} type="submit">
											Pay R{formatCurrency(transactionData.amount / 100)}
										</button>
										{transactionData.displayConfig?.backToOrder && (
											<div className={cardPaymentModule.ButtonBlock_buttonBlock} onClick={() => cancelTransaction(token)}>
												<ReturnToOrder
													trackEvents={transactionData.trackEvents}
													returnUrl={transactionData.cancelUrl}
													eventTracker={eventsTracker}
												/>
											</div>
										)}
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		) : (
			<LoaderThatRedirects success={false} message={"Unable to load paylink"} />
		)
	},
)

export default CardPaymentPage
