export const formatCurrency = (amount: number) => {
	if (amount === null || isNaN(amount) || amount.toString() === '') {
	  return '';
	}
	//Code below formats value by thousands 
	const formatAmount = new Intl.NumberFormat('fr-FR', {
	  style: 'currency',
	  currency: 'ZAR',
	}).format(amount);
	//Replace is done here as when running on the chrome browser above code adds a ZAR | ZA on the after being formatted
	return formatAmount.replace(/ZAR|R/g, '').trim();
  };
