import containerModule from "./index.module.css";
import React from "react";

export interface IContainer {
  src: string;
  alt: string;
  title: string;
  subtitle?: string;
  message?: string;
  errorMessage?: string;
}

export const Container: React.FunctionComponent<IContainer> = (props) => {
  return (
    <main className={containerModule.main_container}>
      <section className={containerModule.main_section}>
        <div className={containerModule.illustration_container}>
          <img src={props.src} alt={props.alt} />
        </div>
        <div className={containerModule.content_container}>
          <div className={containerModule.text_container}>
            <h1>{props.title}</h1>
            {props.subtitle && <h1>{props.subtitle}</h1>}
            {props.errorMessage && (
              <h3 className={containerModule.error_heading}>{props.errorMessage}</h3>
            )}
            {props.message && <p className={containerModule.message}>{props.message}</p>}
          </div>
        </div>
        {props.children}
      </section>
    </main>
  );
};
